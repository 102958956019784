import React from 'react';
import { Routes, Route } from 'react-router-dom';
import About from './About';
import RequestForm from './RequestForm';
import Home from './Home';
import JoinDudes from './JoinDudes';

const MainContent = () => (
    <div>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/request" element={<RequestForm />} />
            <Route path="/join" element={<JoinDudes />} />
        </Routes>
    </div>
);

export default MainContent;
