import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Menu from './components/Menu';
import Footer from './components/Footer';
import MainContent from './components/MainContent';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const App = () => {
  return (
    <Router>
      <div className="App">
        <Menu />
        <MainContent />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
