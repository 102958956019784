import React from 'react';
import logo from '../assets/dude_logo_nobkg.png';
import { Container, Typography, Box, Card, CardContent, Button } from '@mui/material';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

const examples = [
    {
        title: 'Lawn Care',
        description: 'Professional lawn care services to keep your yard looking pristine.'
    },
    {
        title: 'Landscaping',
        description: 'Transform your outdoor space with our landscaping services.'
    },
    {
        title: 'Moving',
        description: 'Efficient and careful moving services to help you relocate with ease.'
    },
    {
        title: 'Minor Repairs',
        description: 'Handy services for minor repairs and maintenance around your home or office.'
    },
    {
        title: 'Junk Removal',
        description: 'Quick and efficient junk removal to help you declutter your space.'
    },
    {
        title: 'Companionship',
        description: 'Friendly companionship services to keep you company and help with tasks.'
    }
];

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const Home = () => (
    <Container>
        <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
            <Typography variant="h3" component="h3" gutterBottom fontFamily={'Courier New'} fontWeight={1000}>
                HIRE A DUDE FOR A DAY!
            </Typography>
            <Link to="/request"><img src={logo} alt="logo" style={{ width: '400px', marginBottom: '10px' }} /></Link>
            <Button component={Link} to="/request" variant="outlined" color="primary" size="large">
                Submit a Request
            </Button>
            <br />
            <Typography variant="h5" component="p" align="center" gutterBottom>
                Need an extra pair of hands? Or maybe a whole team?
                <strong> Hire a Dude</strong> is here to help you with all your tasks around the house or business.
            </Typography>
            <Box mt={4} width="100%">
                <Slider {...settings}>
                    {examples.map((example, index) => (
                        <Box key={index} px={2}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" component="h2">
                                        {example.title}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        {example.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                    ))}
                </Slider>
            </Box>
            <br />
            <Typography variant="h4" component="h3" align="center" gutterBottom>
                What We Offer
            </Typography>
            <Typography variant="body1" component="p" align="center" paragraph>
                Whether it's lawn care, landscaping, moving, minor repairs, maintenance, junk removal, or even just hanging out,
                our dudes are ready to assist you. We provide reliable, friendly, and efficient help for any duration you need.
            </Typography>
            <Typography variant="body1" component="p" align="center" paragraph>
                <strong>The dudes abide.</strong>
            </Typography>
        </Box>
    </Container>
);

export default Home;
