import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import logo from '../assets/dude_logo_flex_light.png';

const Menu = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const menuList = () => (
        <List>
            <ListItem button component={Link} to="/" onClick={toggleDrawer(false)}>
                <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component={Link} to="/request" onClick={toggleDrawer(false)}>
                <ListItemText primary="Submit a Request" />
            </ListItem>
            <ListItem button component={Link} to="/join" onClick={toggleDrawer(false)}>
                <ListItemText primary="Join the Dude Team" />
            </ListItem>
            <ListItem button component={Link} to="/about" onClick={toggleDrawer(false)}>
                <ListItemText primary="About" />
            </ListItem>
        </List>
    );

    return (
        <div>
            <AppBar position="static" sx={{ backgroundColor: '#00000f' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} >
                        <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>HIREADUDE.COM</Link>
                    </Typography>
                    <a href="/"><img src={logo} alt="Dude Logo" style={{ height: '50px' }} /></a>
                </Toolbar>
            </AppBar>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
                {menuList()}
            </Drawer>
        </div>
    );
};

export default Menu;
