import React from 'react';

const JoinDudes = () => (
    <div>
        <h2>Join the Dudes!</h2>
        <p>This is the where your new life with the dudes begins!
            Send an email with your resume to <strong>dudes@hireadude.com</strong>
        </p>
    </div>
);

export default JoinDudes;
