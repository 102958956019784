import React, { useState, ChangeEvent, FormEvent } from 'react';
import { TextField, Button, MenuItem, Typography, Container, Box, Paper } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import './RequestForm.css';  // Import the CSS file for additional styling

interface FormData {
    name: string;
    email: string;
    request: string;
    date: Date | null;
    time: string;
    workType: string;
    customWorkType: string;
}

const workTypes = [
    'Lawn Care',
    'Landscaping',
    'Moving',
    'Minor Repairs',
    'Junk Removal',
    'Companionship',
    'Other'
];

const RequestForm: React.FC = () => {
    const [formData, setFormData] = useState<FormData>({
        name: '',
        email: '',
        request: '',
        date: null,
        time: '',
        workType: '',
        customWorkType: ''
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleDateChange = (date: Date | null) => {
        setFormData({
            ...formData,
            date
        });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const response = await fetch('/send-email.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    ...formData,
                    date: formData.date ? formData.date.toISOString().split('T')[0] : '',
                } as any).toString()
            });

            if (response.ok) {
                alert('Thank you! Your request has been sent.');
            } else {
                alert('There was a problem with your submission, please try again.');
            }
        } catch (error) {
            alert('There was a problem with your submission, please try again.');
        }
    };

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                <Typography variant="h4" component="h2" gutterBottom align="center">
                    Submit a Request
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Box mb={2}>
                        <TextField
                            fullWidth
                            label="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            variant="outlined"
                            required
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            variant="outlined"
                            required
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            fullWidth
                            label="Request Details"
                            name="request"
                            value={formData.request}
                            onChange={handleChange}
                            multiline
                            rows={4}
                            variant="outlined"
                            required
                        />
                    </Box>
                    <Box mb={2}>
                        <DatePicker
                            selected={formData.date}
                            onChange={handleDateChange}
                            dateFormat="MMMM d, yyyy"
                            placeholderText="Select Date"
                            className="full-width date-picker-input"
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            fullWidth
                            select
                            label="Select Time"
                            name="time"
                            value={formData.time}
                            onChange={handleChange}
                            variant="outlined"
                            required
                        >
                            <MenuItem value="Half Day">Half Day</MenuItem>
                            <MenuItem value="Full Day">Full Day</MenuItem>
                        </TextField>
                    </Box>
                    <Box mb={2}>
                        <TextField
                            fullWidth
                            select
                            label="Select Work Type"
                            name="workType"
                            value={formData.workType}
                            onChange={handleChange}
                            variant="outlined"
                            required
                        >
                            {workTypes.map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                    {formData.workType === 'Other' && (
                        <Box mb={2}>
                            <TextField
                                fullWidth
                                label="Custom Work Type"
                                name="customWorkType"
                                value={formData.customWorkType}
                                onChange={handleChange}
                                variant="outlined"
                                required
                            />
                        </Box>
                    )}
                    <Button variant="contained" color="primary" type="submit" fullWidth>
                        Submit
                    </Button>
                </form>
            </Paper>
        </Container>
    );
};

export default RequestForm;
